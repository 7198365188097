import { videoReplayData } from "../assets/data/video";
import { VideoGallery } from "../container/VideoGallery";
import { Link } from "react-router-dom";

export function ReplayPage() {
    return (
        <div className="container">
            <VideoGallery data={videoReplayData} />
            <section className="extra-info-typo">
                <p className="light">Retrouvez <b><Link to={"/rcp"}>ici</Link></b> les caractéristiques produit</p>
            </section>
        </div>
    )
}