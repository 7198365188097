// const BASE_URL = "https://brain-agency.net/loxen/videos/";
const BASE_URL = "https://quandlapressionmonte.com/videos/";
const IMAGE_BASE_URL = "/images/webp/";
const THUMBNAIL_COVER_DEFAULT = IMAGE_BASE_URL + "video1Cover.webp";
const THUMBNAIL_PLAY_DEFAULT = IMAGE_BASE_URL + "playButton.webp";

export const videoData = [
  // video url
  {
    type: "video",
    title: "SAS",
    videoUrl: BASE_URL + "HTA_SAS.webm",
    description:
      "« Syndrome d’apnée du sommeil : un facteur d’aggravation de l’HTA »",
    thumbnail_cover: IMAGE_BASE_URL + "hta_sas_cover.webp",
    thumbnail_play: THUMBNAIL_PLAY_DEFAULT,
  },
  {
    type: "video",
    title: "MET",
    videoUrl: BASE_URL + "Syndrome_Metabolique.webm",
    description: "« Syndrome métabolique : une épidémie en pleine expansion »",
    thumbnail_cover: IMAGE_BASE_URL + "syndrome_metabolique_cover.webp",
    thumbnail_play: THUMBNAIL_PLAY_DEFAULT,
  },
  {
    type: "video",
    title: "Actualité ICA",
    videoUrl: BASE_URL + "Main_3.mp4",
    description: "",
    thumbnail_cover: IMAGE_BASE_URL + "main_3_cover.webp",
    thumbnail_play: THUMBNAIL_PLAY_DEFAULT,
  },
  {
    type: "video",
    title: "Repenser l’HTA en 2023",
    videoUrl: BASE_URL + "Main_1.mp4",
    description: "",
    thumbnail_cover: IMAGE_BASE_URL + "main_1_cover.webp",
    thumbnail_play: THUMBNAIL_PLAY_DEFAULT,
  },
  {
    type: "video",
    title: "HTA ET LES FEMMES",
    videoUrl: BASE_URL + "Main_2.mp4",
    description: "",
    thumbnail_cover: IMAGE_BASE_URL + "main_2_cover.webp",
    thumbnail_play: THUMBNAIL_PLAY_DEFAULT,
  },
  {
    type: "video",
    title: "Le patient et l'automesure",
    videoUrl: BASE_URL + "Part_1_VFIN_SUBTI.mp4",
    description: "",
    thumbnail_cover: IMAGE_BASE_URL + "part_1_vfin_subti_cover.webp",
    thumbnail_play: THUMBNAIL_PLAY_DEFAULT,
  },
  {
    type: "video",
    title: "Les femmes et leur risque vasculaire sous-estimé",
    videoUrl: BASE_URL + "Part_2_VFIN_SUBTI.mp4",
    description: "",
    thumbnail_cover: IMAGE_BASE_URL + "part_2_vfin_subti_cover.webp",
    thumbnail_play: THUMBNAIL_PLAY_DEFAULT,
  },
  {
    type: "video",
    title: "Les inhibiteurs calciques dihydropyridines au centre de la prise en charge",
    videoUrl: BASE_URL + "Part_3_VFIN_SUBTI.mp4",
    description: "",
    thumbnail_cover: IMAGE_BASE_URL + "part_3_vfin_subti_cover.webp",
    thumbnail_play: THUMBNAIL_PLAY_DEFAULT,
  },
];

export const videoReplayData = [
  // replay video url
  {
    type: "video",
    title: "PRISE EN CHARGE DE l'HTA POUR PREVENIR LES AVC-04/24",
    videoUrl: BASE_URL + "ED_XO_CARDIO_24_04_24.mp4",
    description: "",
    thumbnail_cover: IMAGE_BASE_URL + "ED_XO_CARDIO_24_04_24_cover.webp",
    thumbnail_play: THUMBNAIL_PLAY_DEFAULT,
  }
];

export const videoDataInteractive = [
  // video Interactive
  {
    type: "interactive",
    title: "Vidéo 1 : Derrière le 13, tout va bien ?",
    id: "1",
    description:
      "Présenté par le Professeur Olivier HANON (Cardiologue, Hôpital Broca, AP-HP)",
    thumbnail_cover: IMAGE_BASE_URL + "hanon_cover.webp",
    thumbnail_play: THUMBNAIL_PLAY_DEFAULT,
  },
  {
    type: "interactive",
    title: "Vidéo 2 : Ne pas laisser dériver les femmes",
    id: "2",
    description:
      "Présenté par le Docteur Yara ANTAKLY (Cardiologue Service Centre d'Excellence HTA, Chef de service adjoint Service Cardiologie Adulte, Hôpital Paris Saint-Joseph)",
    thumbnail_cover: IMAGE_BASE_URL + "yara_cover.webp",
    thumbnail_play: THUMBNAIL_PLAY_DEFAULT,
  },
];
